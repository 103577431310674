import { UserData } from '../types/common';

export type BIRole = 'member' | 'owner' | 'visitor';

export function userRoleToBIRole(role: UserData['role']): BIRole {
  switch (role) {
    default:
    case 'Member':
      return 'member';
    case 'Admin':
      return 'owner';
    case 'Visitor':
      return 'visitor';
  }
}

export function toBIPaymentType(recurring?: boolean) {
  return recurring ? 'recurring' : 'one-time';
}
