export interface IconProps {
  size: number;
  className: string;
}

export enum BenefitBulletType {
  NONE = 0,
  CIRCLE_TICK = 1,
  HOLLOW_CIRLCE_TICK = 2,
  TICK = 3,
  ARROW = 4,
}
