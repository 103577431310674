import { PaymentCompleteResult } from '@wix/cashier-payments-widget/dist/src/sdk/types/PaymentCompleteResult';

export class PaymentResultReader {
  constructor(protected result: PaymentCompleteResult) {}

  isOk(): boolean {
    return this.result.clientStatus !== 'Failed';
  }

  getTranslatedError(): string | undefined {
    return 'translatedError' in this.result ? this.result.translatedError : undefined;
  }
}
